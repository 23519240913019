'use strict';

angular.module('kljDigitalLibraryApp')
    .service('AccountDetailsService', ["common", "appConfig", "$q", "$cookies",
        function(common, appConfig, $q, $cookies) {
            var url = '/api/users/editProfile';

            this.update = function(body) {

                return common.callApi('PUT',
                    url,
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    body
                )

                .then(function(user) {

                    console.log("user from service-->", user);
                })

                .catch(function(error) {

                    console.log("error service-->", error);
                })
            }

            this.create = function(body) {
                var deferred = $q.defer();

                common.callApi('POST',
                    '/api/users/create/user',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    body
                )

                .then(function(response) {
                    deferred.resolve(response);
                })

                .catch(function(error) {

                    deferred.reject(error);
                })
                return deferred.promise;
            }

            this.verifyLogin = function(body) {

                var deferred = $q.defer();

                common.callApi('POST',
                    '/auth/guestLogin',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    body
                )

                .then(function(response) {
                    $cookies.put('token', response.data.token);
                    deferred.resolve(response);
                })

                .catch(function(error) {

                    deferred.reject(error);
                })
                return deferred.promise;

            }

            this.getList = function() {
                var deferred = $q.defer();
                common.callApi('GET',
                        '/api/common/getOrganizations',
                        appConfig.EMPTY_STRING,
                        appConfig.CONTENT_TYPE
                    )
                    .then(function(response) {
                        deferred.resolve(response.data);
                    })
                    .catch(function(error) {

                        deferred.reject(error.data);
                    })
                return deferred.promise;
            }

            this.storeIpAddress = function(body) {
                var deferred = $q.defer();

                common.callApi('POST',
                    '/api/user_ip_token/storeIpDetails',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    body
                )

                .then(function(response) {
                    deferred.resolve(response);
                })

                .catch(function(error) {

                    deferred.reject(error);
                })
                return deferred.promise;
            }


        }
    ]);